<!--设置管理--用户设置--权限站点设置-->
<template>
  <div
    id="siteJurisdictionSet"
    v-loading="loading"
    :element-loading-text="$t('UserSettings.a1')"
  >
    <!-- 页面标题 -->
    <div class="pageTitle">
      <my-headertitle :quit="true">{{ $t("UserSettings.a70") }}</my-headertitle>
    </div>
    <!-- 头部--卡片和基本信息 -->
    <div class="header">
      <!-- 卡片 -->
      <div class="card">
        <img src="../../../public/images/set/user.png" />
        <span>{{ info.siteName }}</span>
      </div>
      <!-- 基本信息 -->
      <div class="info">
        <div class="info_basics">
          <div>
            <img :src="getimgs.url" />
          </div>
          <div class="info_basics_title">
            <span>{{ info.siteName }}</span>
          </div>
          <div class="info_basics_chunk">
            <p>{{ $t("UserSettings.a71") }}</p>
            <span>{{ info.siteId }}</span>
          </div>
          <div class="info_basics_chunk">
            <p>{{ $t("UserSettings.a72") }}</p>
            <span>{{ info.buildingName }}</span>
          </div>
          <div class="info_basics_chunk">
            <p>{{ $t("UserSettings.a73") }}</p>
            <a-checkbox v-model="info.permissLevel1" :disabled="true">
              {{ $t("UserSettings.a33") }}
            </a-checkbox>
            <a-checkbox v-model="info.permissLevel2" :disabled="true">
              {{ $t("UserSettings.a34") }}
            </a-checkbox>
            <a-checkbox v-model="info.permissLevel3" :disabled="true">
              {{ $t("UserSettings.a35") }}
            </a-checkbox>
          </div>
        </div>
      </div>
    </div>
    <!-- 主内容区域（标签页） -->
    <div class="main">
      <!--  -->
      <div class="tag">
        <a-radio-group v-model="Pageselection" button-style="solid">
          <a-radio-button :value="0">{{
            $t("UserSettings.a74")
          }}</a-radio-button>
          <!-- <a-radio-button :value="1">设备权限</a-radio-button>
          <a-radio-button :value="2">数据权限</a-radio-button> -->
        </a-radio-group>
      </div>
      <!-- <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="菜单服务权限">
          <div class="tab_main">
            <div>
              <span>服务：</span>
              <a-select default-value="lucy" style="width: 120px">
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="disabled"> Disabled </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </div>
            <div>
              <my-tabletitle>权限外服务菜单</my-tabletitle>
              <a-table
                :columns="columns"
                :dataSource="table1"
                :pagination="false"
                :rowKey="(record) => record.Id"
              >
                <template slot="chaozuo" slot-scope="text, record">
                  <a-button type="primary">添加↓</a-button>
                </template>
              </a-table>
            </div>
            <div>
              <my-tabletitle>权限内服务菜单</my-tabletitle>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="设备权限" force-render>
          Content of Tab Pane 2
        </a-tab-pane>
        <a-tab-pane key="3" tab="数据权限"> Content of Tab Pane 3 </a-tab-pane>
      </a-tabs> -->
      <transition name="slide-fade">
        <div class="tabs" v-if="Pageselection == 0">
          <div class="select">
            <span>{{ $t("UserSettings.a75") }}</span>
            <a-select v-model="serviceListSelectValue" style="width: 120px">
              <a-select-option
                v-for="(item, index) in serviceList"
                :key="index"
                :value="item.contractType"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <my-tabletitle>{{ $t("UserSettings.a76") }}</my-tabletitle>
            <a-table
              :columns="outercolumns"
              :dataSource="outerData"
              :pagination="false"
              :rowKey="(record) => record.service.id"
            >
              <template slot="chaozuo" slot-scope="text, record">
                <a-button type="primary" @click="add(record)">
                  {{ $t("UserSettings.a77") }}
                </a-button>
              </template>
            </a-table>
          </div>
          <div>
            <my-tabletitle style="margin-top: 30px">
              {{ $t("UserSettings.a78") }}
            </my-tabletitle>
            <a-table
              :columns="withincolumns"
              :dataSource="withinData"
              :pagination="false"
              :rowKey="(record) => record.service.id"
            >
              <template slot="level1" slot-scope="text, record">
                <a-checkbox v-model="record.level1" @click="reveal(record)">
                </a-checkbox>
              </template>
              <template slot="level2" slot-scope="text, record">
                <a-checkbox
                  v-model="record.level2"
                  :disabled="!record.csv || record.level == 0"
                  @click="setCsv(record)"
                >
                </a-checkbox>
              </template>
              <template slot="chaozuo" slot-scope="text, record">
                <a-button type="primary" @click="dataDelete(record)">
                  {{ $t("UserSettings.a79") }}
                </a-button>
              </template>
            </a-table>
          </div>
          <div class="floor_but">
            <a-button type="primary" ghost @click="dataReset">
              {{ $t("UserSettings.a80") }}
            </a-button>
            <a-button type="primary" @click="setData">
              {{ $t("UserSettings.a81") }}
            </a-button>
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="tabs" v-if="Pageselection == 1">2</div>
      </transition>
      <transition name="slide-fade">
        <div class="tabs" v-if="Pageselection == 2">13</div>
      </transition>
    </div>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle.vue";
import tabletitle from "../../components/Title/tabletitle.vue";
import {
  getsitecontractlist,
  usergrservcies,
  siteservcies,
  setusergrservices,
} from "../../api/setManagement";
import { Base64 } from "js-base64";

export default {
  data() {
    return {
      loading: false,
      info: {},
      Pageselection: 0,
      serviceList: [],
      serviceListSelectValue: 0,
      allData: [],
      withinData: [],
      cacheWithinData: [],
      outerData: [],
      cacheOuterData: [],
      withincolumns: [
        {
          title: "NO",
          customRender: (text, record, index) => `${index + 1}`,
          width: 100,
          align: "center",
        },
        {
          title: this.$t("UserSettings.a82"),
          customRender: (text, record, index) => {
            return record.service.name;
          },
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("UserSettings.a83"),
          customRender: (text, record, index) => {
            return record.service.description;
          },
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("UserSettings.a84"),
          dataIndex: "level1",
          width: 100,
          scopedSlots: { customRender: "level1" },
          align: "center",
          ellipsis: true,
        },
        {
          title: "CSV",
          dataIndex: "level2",
          width: 100,
          scopedSlots: { customRender: "level2" },
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("UserSettings.a34"),
          dataIndex: "chaozuo",
          align: "center",
          scopedSlots: { customRender: "chaozuo" },
          width: 120,
        },
      ],
      outercolumns: [
        {
          title: "NO",
          customRender: (text, record, index) => `${index + 1}`,
          width: 100,
          align: "center",
        },
        {
          title: this.$t("UserSettings.a82"),
          customRender: (text, record, index) => {
            return record.service.name;
          },
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("UserSettings.a83"),
          customRender: (text, record, index) => {
            return record.service.description;
          },
          align: "center",
          ellipsis: true,
        },
        {
          title: this.$t("UserSettings.a34"),
          dataIndex: "chaozuo",
          align: "center",
          scopedSlots: { customRender: "chaozuo" },
          width: 120,
        },
      ],
    };
  },

  computed: {
    // 根据合同类型获取对应的图标
    getimgs() {
      const value = this.info.siteContractType;
      switch (value) {
        case 1: //门禁
          return {
            url: require("../../../public/images/moduleimg/door.png"),
          };
          break;
        case 2: //通用设备管理
          return require("../../../public/images/moduleimg/equipment.png");
          break;
        case 3: //多联机空调
          return require("../../../public/images/moduleimg/vrfac.png");
          break;
        case 4: //水冷空调
          return require("../../../public/images/moduleimg/bsdsac.png");
          break;
        case 5: //其他空调
          return require("../../../public/images/moduleimg/airconditioner.png");
          break;
        case 6: //环境
          return require("../../../public/images/moduleimg/environment.png");
          break;
        case 7: //照明
          return require("../../../public/images/moduleimg/light.png");
          break;
        case 8: //电能
          return require("../../../public/images/moduleimg/electricity.png");
          break;
        case 9: //水能
          return require("../../../public/images/moduleimg/water.png");
          break;
        case 10: //视频监控
          return require("../../../public/images/moduleimg/video.png");
          break;
        case 11: //电梯管理
          return require("../../../public/images/moduleimg/elevator.png");
          break;
        case 12: //消费管理
          return require("../../../public/images/moduleimg/consumption.png");
          break;
        case 13: //出入口
          return require("../../../public/images/moduleimg/passageway.png");
          break;
        case 14: //访客
          return require("../../../public/images/moduleimg/visitor.png");
          break;
        case 15: //考勤
          return require("../../../public/images/moduleimg/checkingIn.png");
          break;
        case 16: //会议
          return require("../../../public/images/moduleimg/conference.png");
          break;
        default:
          return require("../../../public/images/moduleimg/else.png");
      }
    },
  },
  mounted() {
    let info = JSON.parse(Base64.decode(this.$route.query.id));
    if (info.permissLevel == 1) {
      info.permissLevel1 = true;
      info.permissLevel2 = false;
      info.permissLevel3 = false;
    } else if (info.permissLevel == 2) {
      info.permissLevel1 = true;
      info.permissLevel2 = true;
      info.permissLevel3 = false;
    } else if (info.permissLevel == 3) {
      info.permissLevel1 = true;
      info.permissLevel2 = true;
      info.permissLevel3 = true;
    }
    this.info = info;
    // console.log(this.info);
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let siteId = this.info.siteId;
      let data = {
        siteId,
      };
      // 获取服务下来菜单的数据
      await getsitecontractlist(data)
        .then((res) => {
          // console.log(res);
          let { data } = res;
          this.serviceList = data;
          this.serviceListSelectValue = data[0].contractType;
        })
        .catch((err) => {
          console.log(err);
        });
      let contractId = this.serviceListSelectValue;
      let siteservciesData = {
        contractId,
      };
      // 获取所有权限的服务菜单数据
      await siteservcies(siteservciesData)
        .then((res) => {
          // console.log(res);
          const { data } = res;
          this.allData = data;
        })
        .catch((err) => {
          console.log(err);
        });
      let srvContractId = this.serviceListSelectValue;
      let userGrId = this.info.userGrId;
      let usergrservciesdata = {
        srvContractId,
        userGrId,
      };
      // 获取权限内的服务菜单数据
      await usergrservcies(usergrservciesdata)
        .then((res) => {
          // console.log(res);
          const { data } = res;
          for (let i = 0; i < data.length; i++) {
            if (data[i].level == 0) {
              data[i].level1 = false;
              data[i].level2 = false;
            } else if (data[i].level == 1) {
              data[i].level1 = true;
              data[i].level2 = false;
            } else if (data[i].level == 3) {
              data[i].level1 = true;
              data[i].level2 = true;
            }
          }
          this.withinData = data;
          const _val = JSON.stringify(data);
          const val = JSON.parse(_val);
          this.cacheWithinData = val;
          this.outerDataHandle();
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    // 权限外服务菜单数据操作
    outerDataHandle() {
      const allData = this.allData;
      const withinData = this.withinData;
      const outerData = [];
      for (let i = 0; i < allData.length; i++) {
        let result = withinData.findIndex(
          (list) => list.service.id === allData[i].service.id
        );
        if (result == -1) {
          outerData.push(allData[i]);
        }
      }
      this.outerData = outerData;
      const _val = JSON.stringify(outerData);
      const val = JSON.parse(_val);
      this.cacheOuterData = val;
    },
    // 权限内服务菜单显示操作
    reveal(value) {
      setTimeout(() => {
        if (value.level1) {
          value.level = 1;
        } else {
          value.level = 0;
          value.level2 = false;
        }
      }, 10);
    },
    // 权限内服务菜单csv操作
    setCsv(value) {
      setTimeout(() => {
        if (value.level2) {
          value.level = 3;
        } else {
          value.level = 1;
        }
      });
    },
    // 权限内服务菜单数据增加
    add(value) {
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      const outerData = this.outerData;
      val.level = 0;
      val.level1 = false;
      val.level2 = false;
      this.withinData.push(val);
      let result = outerData.findIndex(
        (list) => list.service.id === val.service.id
      );
      outerData.splice(result, 1);
    },
    // 数据重置
    dataReset() {
      this.withinData = this.cacheWithinData;
      this.outerData = this.cacheOuterData;
    },
    // 权限内服务菜单数据删除
    dataDelete(value) {
      // console.log(value);
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      const withinData = this.withinData;
      val.level = 0;
      val.level1 = false;
      val.level2 = false;
      this.outerData.push(val);
      let result = withinData.findIndex(
        (list) => list.service.id === val.service.id
      );
      withinData.splice(result, 1);
    },
    // 权限内服务菜单数据更新
    setData() {
      this.loading = true;
      const servicesInfo = [];
      const withinData = this.withinData;
      const svrContractId = this.serviceListSelectValue;
      const userGrId = this.info.userGrId;
      for (let i = 0; i < withinData.length; i++) {
        if (withinData[i].level !== 0) {
          servicesInfo.push([withinData[i].service.id, withinData[i].level]);
        }
      }
      const query = {
        svrContractId,
        userGrId,
      };
      setusergrservices(servicesInfo, query)
        .then((res) => {
          // console.log(res);
          this.getData();
          if (res.errorCode == "00") {
            this.$message.success(this.$t("UserSettings.a85"));
          } else {
            this.$message.error(this.$t("UserSettings.a86"));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
#siteJurisdictionSet {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #bdbcbc;
}
.pageTitle {
  padding: 0px 20px;
}
.header {
  padding: 20px 20px 0px 20px;
  overflow: hidden;
}
.card {
  display: flex;
  align-items: center;
  width: 250px;
  height: 80px;
  padding: 20px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #7682ce;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card img {
  margin-right: 20px;
}
.card span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 20px;
  background-color: #f3f4f8;
  white-space: nowrap;
  overflow: hidden;
}
.info img {
  width: 32px;
  margin-right: 10px;
}
.info_basics {
  display: flex;
  align-items: center;
}
.info_basics_title {
  font-size: 16px;
  margin-right: 40px;
}
.info_basics_chunk {
  margin-right: 30px;
}
.info_basics_chunk p {
  font-size: 14px;
  margin-bottom: 5px;
}
.info_basics_chunk span {
  font-size: 14px;
  color: rgba(12, 12, 12, 0.65);
}
.main {
  position: relative;
  height: calc(100% - 265px);
}
.select {
  font-size: 14px;
  color: rgba(12, 12, 12, 0.65);
  margin-bottom: 15px;
}
.tag {
  margin-bottom: 15px;
  padding: 0px 20px;
}
.tabs {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  padding: 0px 20px;
  overflow: auto;
}
.floor_but {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
}
.floor_but button {
  margin-right: 15px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>